.scrollable {
  max-height: 80vh;
  overflow: auto;
}

.checkboxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.gridContainer {
  display: grid;
}

.headerCell {
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-m);
  line-height: var(--line-height-l);
  font-weight: var(--font-weight-regular);
  color: var(--sonic-silver);
  background-color: var(--blue-grey);
}

.headerCellSticky {
  position: sticky;
  top: 0;
}

.subHeader {
  grid-column-start: 1;
}

.group {
  box-shadow: var(--box-shadow-regular);
  border-radius: 4px;
}

.groupBackground {
  background-color: var(--white);
}

.dataCell {
  overflow: hidden;

  font-family: var(--font-family-rubik);
  font-size: var(--font-size-l);
  line-height: var(--line-height-xl);
  font-weight: var(--font-weight-regular);
  color: var(--black);
}

.noGroupCell {
  margin: 0 16px;
  border-radius: 4px;
  background-color: var(--white);
}

.groupStartCell {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.groupEndCell {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.clickableCell {
  cursor: pointer;
}

.expandableRow {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  position: relative;
}

.expandableContent {
  width: 100%;
}

.expandButton {
  position: absolute;
  top: -8px;
  margin: -16px auto;
  left: 50%;
  transform: translateX(-50%);
}

.expandButtonBreakpoint {
  align-items: stretch;
}

.rowSpacer {
  height: 16px;
}

.emptyMessage {
  padding: 20px;
  text-align: center;
}

.columnHighlighted {
  background-color: #eee;
}

.loadingIndicator {
  padding: 20px;
  display: flex;
  justify-content: center;
}

.actionsCell {
  position: sticky;
  right: 0;
  display: flex;
  align-items: center;
  background-color: var(--white);
}
