.radio {
  &:global(.ant-radio-wrapper) {
    align-items: center;
  }

  :global(.ant-radio-inner) {
    border-color: var(--sonic-silver);

    &:after {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      margin: 0;
    }
  }

  :global(.ant-radio) {
    top: 0;

    & + span {
      padding: 0;
      color: var(--text-color-primary);
      font-family: var(--font-family-rubik);
      font-weight: var(--font-weight-medium);
      font-style: normal;
      text-transform: uppercase;
    }
  }

  &.checked {
    :global(.ant-radio-inner) {
      background-color: var(--white);
      border-color: var(--sonic-silver);

      &:after {
        border-color: var(--blue-ribbon);
      }
    }
  }

  &.small {
    :global(.ant-radio-inner) {
      width: 24px;
      height: 24px;

      &:after {
        border-width: 2px;
      }
    }

    :global(.ant-radio + span) {
      margin: 0 0 0 16px;
      font-size: var(--font-size-m);
    }
  }

  &.big {
    :global(.ant-radio-inner) {
      width: 32px;
      height: 32px;

      &:after {
        border-width: 4px;
      }
    }

    :global(.ant-radio + span) {
      margin: 0 0 0 24px;
      font-size: var(--font-size-l);
    }
  }
}

.radiosGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;

  :global(.ant-radio-wrapper) {
    margin: 0;
  }
}

.radiosItem {
  flex-grow: 0;
  flex-shrink: 0;
}
