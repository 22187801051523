.root {
  overflow-y: scroll;
}
.columns {
  display: flex;
  gap: 20px;
}

.column {
  flex: 1 1 auto;
}

.hidden {
  opacity: 0.6;
}

.indicator {
  height: 100%;
}
