.collectibleTitleText {
  color: var(--black);
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  letter-spacing: -0.2px;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.collectibleDescriptionText {
  color: var(--black);
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  letter-spacing: -0.17px;
  line-height: 1.2;
  white-space: normal;
}

.large {
  &.collectibleTitleText {
    font-size: var(--font-size-xxl);
    font-weight: var(--font-weight-medium);
    line-height: 1.2;
  }

  &.collectibleDescriptionText {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-regular);
  }
}
