.profileMenu {
  position: relative;
  background-color: var(--blue-grey-darker);
  border: 1px solid var(--celeste);
  border-right: none;
  border-bottom: none;
  border-top: none;
  height: 100%;
}

.profileIcon {
  padding: 12px;
  align-self: center;
  border: none;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
  background-color: var(--concrete-lighter);
}

.content {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  padding: 8px;
  z-index: 100;
  background-color: var(--blue-grey-darker);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  min-width: 300px;
  overflow: auto;
  margin-top: 2px;
  margin-right: 2px;
}

.item {
  padding: 8px 2px;
}

.action {
  &:hover {
    cursor: pointer;
    background-color: var(--white);
    margin-left: -8px;
    padding-left: 10px;
    width: calc(100% + 18px);
  }
}
.itemLabel {
  margin-left: 4px;
  text-transform: uppercase;
}

.name {
  font-size: 15px;
  font-weight: var(--font-weight-medium);
}
.separator {
  margin-left: -10px;
  width: calc(100% + 20px);
  border-top: 1px solid var(--celeste);
  margin-top: 5px;
}

.appDetailsContent {
  & > p {
    margin-bottom: unset;
  }
  padding: 16px 0 0 0;
}

.tierName {
  text-transform: uppercase;
  line-height: var(--line-height-xxxxl);
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-rubik);
}

.version {
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-rubik);
  &::before {
    content: ' ';
  }
}
