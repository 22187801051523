.overlay {
  background-color: var(--white);
  box-shadow: var(--box-shadow-small);
}

.list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.item {
  transition: background-color var(--transition-duration-snappy);

  &:hover {
    background-color: var(--concrete);
  }
}

:global(.ant-checkbox-wrapper) {
  &.checkbox {
    padding: 8px 16px;
    width: 100%;
  }

  &.allCheckbox {
    padding: 8px 16px;
    margin-bottom: 4px;
    width: 100%;
    border-bottom: 1px solid var(--gray);
  }
}
