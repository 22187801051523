.sideMenu {
  width: 100px;
  background-color: var(--black);
  color: var(--white);
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  .menuItem {
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: column;
    padding: 10px 4px 5px 4px;
    align-items: center;
  }

  :global(.ant-menu-vertical) {
    flex: 1 1 auto;
    overflow: auto;
    background-color: inherit;
    color: inherit;
    border: none;

    :global(.ant-menu-submenu) {
      padding: 0;

      :global(.ant-menu-submenu-arrow) {
        display: none;
      }
    }

    :global(.ant-menu-item),
    :global(.ant-menu-submenu) > :global(.ant-menu-submenu-title) {
      margin: 0;
      padding: 0;
      min-height: 80px;
      height: max-content;
      a {
        color: inherit;
      }

      &:hover {
        color: inherit;
        background-color: var(--blue-hover);
      }
    }

    :global(.ant-menu-item.ant-menu-item-selected),
    :global(.ant-menu-submenu-selected) {
      color: inherit;
      background-color: var(--gull-gray-translucent);
    }

    :global(.ant-menu-item-active),
    :global(.ant-menu-submenu-active) {
      color: inherit;
      background-color: var(--blue-hover);
    }
  }
}

.subMenuItemsPopUp {
  .menuItem {
    display: flex;
    align-items: center;
    padding-right: 0.75rem;
  }

  .icon {
    flex: 0 0 auto;
    width: 40px;
  }

  &:global(.ant-menu-submenu-popup) {
    background-color: var(--blue-hover);
    color: var(--white);
  }

  :global(.ant-menu-vertical.ant-menu.ant-menu-sub) {
    background-color: inherit;
    color: inherit;
    border-radius: 0;
    margin-left: -5px;

    :global(.ant-menu-item) {
      padding: 0;

      a {
        color: inherit;
      }

      &:hover {
        color: inherit;
        background-color: var(--blue-dark);
      }
    }

    :global(.ant-menu-item.ant-menu-item-selected) {
      color: inherit;
      background-color: var(--gull-gray-translucent);
    }

    :global(.ant-menu-item-active),
    :global(.ant-menu-submenu-active) {
      color: inherit;
      background-color: var(--blue-dark);
    }
  }
}

.logo {
  flex: 0 0 auto;
  display: block;
  padding: 10px;
  margin: 5px 20px;
  align-items: center;
  cursor: pointer;
}

.groupLabel {
  margin-top: 14px;

  & > p {
    line-height: 0.5;
    text-align: center;
  }
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
