.root {
  display: flex;
  align-items: center;

  font-family: var(--font-family-rubik);
  font-size: var(--font-size-m);
  line-height: var(--line-height-l);
  font-weight: var(--font-weight-regular);
  color: var(--sonic-silver);
}

.justifyStart {
  justify-content: flex-start;
  text-align: start;
}

.justifyEnd {
  justify-content: flex-end;
  text-align: end;
}

.justifyCenter {
  justify-content: center;
  text-align: center;
}

.sortButton {
  margin-right: -8px;
}
