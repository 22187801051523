.root {
  &:global(.ant-tabs-card) {
    &:global(.ant-tabs-top) {
      & > :global(.ant-tabs-nav) {
        margin: 0;

        &::before {
          border-bottom: none;
        }

        :global(.ant-tabs-nav-wrap) {
          padding-top: 4px;
        }

        :global(.ant-tabs-tab) {
          margin: 0px 14px;
          font-style: normal;
          padding: 16px 16px;
          box-shadow: var(--box-shadow-regular);
          border: none;
          border-radius: 4px 4px 0 0;

          &:global(.ant-tabs-tab-active) {
            background-color: var(--white);
            color: var(--black);

            :global(.ant-tabs-tab-btn) {
              color: var(--black);
            }
          }
        }
      }
    }

    :global(.ant-tabs-content-holder) {
      box-shadow: var(--box-shadow-regular);
      border-radius: 4px;
    }

    :global(.ant-tabs-content) {
      height: 100%;
    }
  }
}

.primary {
  &:global(.ant-tabs-card) {
    &:global(.ant-tabs-top) {
      & > :global(.ant-tabs-nav) {
        :global(.ant-tabs-tab) {
          background-color: var(--blue-grey-darker);
          font-family: var(--font-family-poppins);
          font-size: var(--font-size-xxxl);
          line-height: var(--line-height-xxxl);
          text-transform: uppercase;

          &:global(.ant-tabs-tab-active) {
            background-color: var(--blue-grey);
            color: var(--black);

            :global(.ant-tabs-tab-btn) {
              color: var(--black);
            }
          }
        }
      }
    }

    :global(.ant-tabs-content-holder) {
      background-color: var(--blue-grey);
    }
  }
}

.secondary {
  &:global(.ant-tabs-card) {
    &:global(.ant-tabs-top) {
      & > :global(.ant-tabs-nav) {
        margin: 0;

        :global(.ant-tabs-tab) {
          background-color: var(--blue-grey-darker);
          color: var(--granite-gray);
          font-size: var(--font-size-xl);
          line-height: var(--line-height-xxxl);
          font-weight: var(--font-weight-bolder);
          height: 56px;
          transform: translateY(8px);

          &:global(.ant-tabs-tab-active) {
            background-color: var(--white);
            color: var(--black);
            transform: translateY(0);

            :global(.ant-tabs-tab-btn) {
              color: var(--black);
            }
          }
        }
      }
    }

    :global(.ant-tabs-content-holder) {
      background-color: var(--white);
    }
  }
}

.dropdown {
  margin-top: 20px;
  margin-bottom: 8px;
}
