.root {
  &:global(.ant-input-number) {
    width: initial;
    border-color: var(--celeste);
    border-radius: var(--border-radius);
    display: inline-flex;
    align-items: center;
    height: 40px;
  }
  :global(.ant-input-number-input-wrap) {
    flex: 1 1 auto;
  }

  :global(.ant-input-number-group) {
    :global(.ant-input-number) {
      width: 100%;
    }
  }

  input {
    font-family: var(--font-family-lato);
    font-size: var(--font-size-l);
  }
}
