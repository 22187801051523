@import '../../styles/breakpoints.scss';

.root {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.wide {
  gap: 12px;
}

.dense {
  gap: 4px;
}

.hint {
  color: var(--text-color-primary);
  font-family: var(--font-family-rubik);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-m);
  margin-right: 4px;
}

:global(.ant-btn) {
  &.button {
    text-transform: uppercase;
    color: var(--text-color-primary);
    font-family: var(--font-family-rubik);
    font-weight: var(--font-weight-regular);
    border-color: var(--granite-gray);
    letter-spacing: -0.32px;
    line-height: 18px;

    @include lgUp {
      margin-bottom: 0;
    }

    &:hover {
      color: var(--text-color-primary);
    }
    &:focus {
      color: var(--text-color-primary);
    }

    &:active {
      color: var(--text-color-primary);
    }
  }
  &.buttonSelected {
    background-color: var(--granite-gray);
    color: var(--white);

    &:hover,
    &:focus,
    &:active {
      background-color: var(--granite-gray);
      color: var(--white);
    }
  }
}
