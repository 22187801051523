.details {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 4px;
}

.text {
  margin: 0;
}

.titleRow {
  display: flex;
  align-items: center;
  gap: 4px;
}

.spacer {
  flex: 1 1 auto;
}
