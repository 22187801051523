@import '../../../sci-ui-components/styles/breakpoints.scss';

.tooltipStat1 {
  flex: 1 1 calc(35% - 4px);
  min-width: 60px;
}

.tooltipStat2 {
  flex: 1 1 calc(65% - 4px);
  min-width: 100px;
}

.fullscreen {
  background-color: var(--blue-grey);
  padding: 28px 60px;
  display: flex;
  flex-direction: column;
}

.tabs {
  flex: 1 1 auto;
}

.legend {
  flex: 0 0 auto;
  margin-top: 20px;
}

.customLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.customLabelImage {
  overflow: visible;
  img {
    margin-bottom: 4px;
  }
}

.customXAxis {
  overflow: visible;
}

.chartHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;

  @include md {
    gap: 20px;
  }

  @include smDown {
    flex-wrap: wrap;
    gap: 12px;
  }
}

.chartHeaderColumn {
  flex-direction: column;
  align-items: flex-start;
}

.chartsHeaderSection {
  gap: 0px;
}

.chartsWrapperRoot {
  padding: 24px 0px;
}
