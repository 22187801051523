.root {
  padding: 8px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}

.inner {
  overflow: hidden;
  flex: 1 1 auto;
}
