.datePicker {
  display: inline-flex;
  align-items: center;
  position: relative;

  :global(.ant-picker-range) {
    &.hiddenAntInput {
      // this is an anchor element for popup so it has to be rendered, but invisible
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0;

      :global(.ant-picker-input) {
        // prevent focus on inputs
        display: none;
      }
    }
  }
}

.dropdown {
  :global(.ant-picker-panels) {
    // ant component is not responsive
    justify-content: center;
    flex-wrap: wrap;
  }
}
