.button {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--celeste);
  background-color: var(--white);
  cursor: pointer;
  padding: 0;

  .icon {
    height: 28px;
    width: 28px;
    margin: 10px;
    color: var(--gull-gray);
  }

  .content {
    border-left: 1px solid var(--celeste);
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
  }

  .label {
    color: var(--gull-gray);
    font-family: var(--font-family-rubik);
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-medium);
    font-style: normal;
    letter-spacing: -0.2px;
    line-height: 14px;
    text-transform: uppercase;
  }

  .selectedRange {
    color: var(--text-color-primary);
    font-family: var(--font-family-rubik);
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-medium);
    font-style: normal;
    letter-spacing: -0.2px;
    line-height: 14px;
  }

  &:hover,
  &:focus {
    color: var(--ant-primary-color-hover);
    border-color: var(--ant-primary-color-hover);

    .content {
      border-color: var(--ant-primary-color-hover);
    }

    .icon,
    .label {
      color: var(--ant-primary-color-hover);
    }
  }

  &:active {
    color: var(--ant-primary-color-active);
    border-color: var(--ant-primary-color-active);

    .content {
      border-color: var(--ant-primary-color-active);
    }

    .icon,
    .label,
    .selectedRange {
      color: var(--ant-primary-color-active);
    }
  }

  &[disabled] {
    color: var(--gull-gray);
    border-color: var(--celeste);
    cursor: not-allowed;

    .content {
      border-color: var(--celeste);
    }

    .icon,
    .label,
    .selectedRange {
      color: var(--gull-gray);
    }

    &:hover,
    &:focus,
    &:active {
      color: var(--gull-gray);
      border-color: var(--celeste);

      .content {
        border-color: var(--celeste);
      }

      .icon,
      .label,
      .selectedRange {
        color: var(--gull-gray);
      }
    }
  }
}
