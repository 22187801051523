.root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 24px 16px;
  position: relative;
  width: 100%;
  background-color: var(--white);
}

.noHeight {
  height: 100%;
}

.headerSection {
  display: flex;
  justify-content: space-between;
  margin: 0 8px 16px 8px;
  gap: 16px;
  align-items: center;
}

.footerSection {
  display: flex;
  justify-content: space-between;
  margin: 0 8px 16px 8px;
  gap: 16px;
  align-items: center;
}

.chart {
  color: var(--gull-gray);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-regular);
  font-style: normal;
}

.spacer {
  flex: 1 1 auto;
}

.settings {
  flex: 0 0 auto;
  margin: 0 8px;
  display: flex;
  gap: 4px;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &:global(.ant-spin-spinning) {
    position: absolute;
  }
}

.emptyMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrapper {
  display: flex;
  flex: 1 1 auto;
}

.legendSection {
  display: flex;
  padding-top: 1.5rem;
  margin: auto 0; // Trying to vertically center the legend's list items
}
