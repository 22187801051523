.positive {
  color: var(--atlantis);
}

.negative {
  color: var(--monza);
}

.primary {
  .stat {
    font-weight: var(--font-weight-medium);
  }
}

.secondary {
  .stat {
    font-weight: var(--font-weight-regular);
  }
}

.statWithSubText {
  padding-top: calc(var(--line-height-l) + 4px);
}

.subText {
  margin-top: 4px;
  font-size: var(--font-size-m);
  line-height: var(--line-height-l);
  color: var(--sonic-silver);
}

.icon {
  margin-right: 4px;
}

.statLink {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.additionalStatText {
  font-size: var(--font-size-s);
  margin-left: 4px;
}

.additionalStatNeutralText {
  color: var(--sonic-silver);
}
