.root {
  display: flex;
  gap: 16px;
  align-items: stretch;
}

.image {
  flex: 0 0 auto;
}

.regular {
  width: 84px;
}

.small {
  width: 56px;
}

.details {
  flex: 1 1 auto;
}

.tiny {
  width: 40px;
}
