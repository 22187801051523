.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  transition: opacity var(--transition-duration-medium);
  opacity: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  filter: blur(12px);
  z-index: var(--z-index-mobile-menu);
}

.backdropOpen {
  pointer-events: all;
  opacity: 1;
}

.container {
  z-index: var(--z-index-mobile-menu);
  pointer-events: none;
  transition: opacity var(--transition-duration-medium), border-radius var(--transition-duration-medium),
    width var(--transition-duration-medium), height var(--transition-duration-medium),
    margin-left var(--transition-duration-medium), margin-top var(--transition-duration-medium);
  opacity: 0;
  width: 0;
  height: 0;
  border-radius: 40px;
  overflow: hidden;
  position: fixed;
  background-color: var(--blue-hover);
  color: var(--white);
  display: flex;
  flex-direction: column;
}

.open {
  pointer-events: all;
  opacity: 1;
  border-radius: 0;
  width: 100vw;
  height: 100vh;
}

.menuHeader {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 20px;
  width: 100vw;
}

.logo {
  flex: 0 0 auto;
  display: block;
  cursor: pointer;
}

.title {
  flex: 1 1 auto;
  font-size: var(--font-size-xxl);
  line-height: var(--line-height-xxl);
  font-family: var(--font-family-poppins);
  font-weight: var(--font-weight-medium);
  color: var(--white);
  text-transform: uppercase;
  margin: 0;
  text-align: center;
}

.closeButton {
  flex: 0 0 auto;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow: auto;
}

.listItem {
  margin: 0;
  padding: 0;
}

.separator {
  padding-top: 16px;
}

.item {
  border: none;
  outline: none;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  gap: 16px;
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-xxl);
  line-height: var(--line-height-xxl);
  font-weight: var(--font-weight-regular);
  color: inherit;
  background-color: inherit;
  text-align: start;
  width: 100%;

  &:hover,
  &:active {
    color: inherit;
    background-color: inherit;
  }
}

.icon {
  flex: 0 0 auto;
  width: 32px;
  height: 32px;
}

.labelWrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 4px;
}

.label {
  width: 100%;
}

.itemNote {
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--font-weight-regular);
}

.subItem {
  padding-left: 36px;

  .icon {
    width: 28px;
    height: 28px;
  }
}

.groupIndicator {
  flex: 0 0 auto;
  width: 28px;
  height: 28px;
  transition: transform var(--transition-duration-snappy);
}

.groupIndicatorOpen {
  transform: rotate(90deg);
}

.disabled {
  opacity: 0.4;
}

.active {
  color: inherit;
  background-color: var(--blue-dark);
}
