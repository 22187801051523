@import '../../styles/breakpoints.scss';

.root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 4px 8px;
  border: 1px solid var(--celeste);
  box-shadow: var(--box-shadow-small);
  background-color: var(--white);
}

.label {
  width: 100%;
  margin: 0 0 2px 0;
  color: var(--text-color-primary);
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  text-align: right;

  @include lgDown {
    font-size: var(--font-size-m);
  }
}

.lines {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  list-style: none;
  margin: 0;
  padding: 0;
}

.line {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  list-style: none;
  margin: 0;
  padding: 0 0 4px 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--celeste);
  }
}

.indicator {
  width: 8px;
  flex: 0 1 auto;
  margin-right: 4px;
}

.lineContents {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 4px;
}

.lineHeader {
  margin: 0;
  width: 100%;
  font-family: var(--font-family-rubik);
  color: var(--text-color-primary);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-regular);
  font-style: normal;

  @include lgDown {
    font-size: var(--font-size-s);
  }
}

.preview {
  max-width: 55px;
  text-align: center;
  img {
    max-width: 100%;
  }
}

.lineStats {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  column-gap: 8px;
  row-gap: 4px;

  @include lgDown {
    font-size: var(--font-size-s);
  }
}
